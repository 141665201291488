.success_page_container {
	margin: 50px auto;
	padding: 20px;
	background-color: #fff;
	border-radius: 5px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	text-align: center;
}

.successPage_title{
	font-size: 36px;
	color: #333;
	margin-bottom: 20px;
}

.successPage_subtitle {
	font-size: 24px;
	color: #666;
	margin-bottom: 30px;
}